<template>
  <v-row>
    <v-col :md="fullWidth ? 6 : 3">
      <v-combobox
        ref="city"
        v-model="cityIntern"
        :items="citySelection"
        :loading="loadingCities"
        :search-input.sync="search"
        hide-no-data
        :item-text="getItemText"
        item-value="city, zip"
        label="Ort"
        :prepend-inner-icon="cityIntern || districtIntern ? '' : 'mdi-magnify'"
        :prepend-icon="districtIntern ? 'mdi-close-octagon-outline' : ''"
        append-icon=""
        placeholder="Start typing to Search"
        clearable
        :dense="dense"
        :filled="filled"
        return-object
        key-name="city"
        :disabled="districtIntern ? true : false"
      ></v-combobox>
    </v-col>
    <v-col :md="fullWidth ? 6 : 3">
      <v-combobox
        ref="district"
        v-model="districtIntern"
        :items="districtItems"
        :loading="loadingDistricts"
        :search-input.sync="districtSearch"
        hide-no-data
        :item-text="getDistrictItemText"
        label="Ortsteil"
        :prepend-inner-icon="
          districtIntern ? 'mdi-map-check-outline' : 'mdi-magnify'
        "
        append-icon=""
        placeholder="Start typing to Search"
        clearable
        :dense="dense"
        :filled="filled"
        return-object
        key-name="district"
      ></v-combobox>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import debounce from 'debounce';

export default {
  name: 'AddressDistrictSelection',
  components: {},
  props: {
    city: {
      type: Object,
      required: false,
      default: null
    },
    district: {
      type: String,
      required: false,
      default: ''
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    filled: {
      type: Boolean,
      required: false,
      default: false
    },
    noIcons: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    addresses: undefined,
    toggles: [],
    activeItem: {},
    cityIntern: null,
    districtIntern: '',
    districtItems: [],
    cities: [],
    citySelection: undefined,
    loading: false,
    isUpdating: false,
    search: '',
    districtSearch: '',
    loadingCities: false,
    loadingDistricts: false,
    rawResult: undefined,
    pastURL: 'https://api.goetel.de/address/',
    headers: {
      Authorization: `Basic ${process.env.VUE_APP_PAST_CREDENTIALS}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    }
  }),
  computed: {
    districtInternComputed() {
      return this.districtIntern;
    },
    selectAllDistricts() {
      return this.district.length === this.districts.length;
    },
    selectSomeDistrict() {
      return this.district.length > 0 && !this.selectAllDistricts;
    },
    icon() {
      if (this.selectAllDistricts) return 'mdi-close-box';
      if (this.selectSomeDistrict) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    mergedAddresses() {
      let merged = [];
      if (
        this.rawResult &&
        this.rawResult.addresses &&
        this.rawResult.addresses.length > 0
      ) {
        this.rawResult.addresses.forEach(function (item) {
          merged = [...merged, ...item];
        });
      }
      return merged;
    }
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    search(value) {
      if (!value) {
        return;
      }
      if (value.length >= 3) {
        debounce(this.makeSearch, 900)(value, this);
      } else {
        this.citySelection = [];
      }
    },
    districtSearch(value) {
      if (!value && !this.cityIntern) {
        this.districtItems = [];
        return;
      }
      if (!this.cityIntern && value.length >= 3 && !this.district.district) {
        debounce(this.getUnallocatedDistricts(value), 900);
      }
    },
    cityIntern(value) {
      this.$emit('updateCity', value);
      if (!value) {
        this.districtItems = [];
        this.citySelection = [];
        return;
      }
      if (value.city.length >= 3 && value.zip) {
        this.getDistricts(value.city, value.zip);
      }
    },
    districtIntern(value) {
      if (
        value &&
        value.district &&
        value.district.length &&
        value.city &&
        value.city.length
      ) {
        this.$emit('updateDistrictObject', value);
        this.$emit('updateDistrict', value.district);
        this.loadingDistricts = false;
      } else if (value && value.district && value.district.length) {
        this.$emit('updateDistrict', value.district);
        this.loadingDistricts = false;
      } else if (!value) {
        this.$emit('updateDistrictObject', undefined);
        this.$emit('updateDistrict', '');
      }
    },
    district: function (value) {
      this.districtIntern = value;
    },
    city: function (value) {
      this.cityIntern = value;
    }
  },
  mounted() {
    this.cityIntern = this.city;
    this.districtIntern = this.district;
  },
  methods: {
    row_classes(item) {
      if (item.match === 1) {
        return 'highlighted-row';
      }
    },
    getAddresses() {
      axios
        .get(this.pastURL + 'geo/addresses', {
          headers: this.headers
        })
        .then((response) => (this.addresses = response.data))
        .catch((error) => console.log(error));
    },
    async makeSearch(value, self) {
      const _this = this;
      if (!value) {
        self.cityIntern = '';
      }
      if (self.loadingCities) {
        return;
      }

      const formattedValue = value
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss');

      if (!formattedValue.includes('(') && !formattedValue.includes(')')) {
        self.loadingCities = true;

        axios
          .get(
            _this.pastURL +
              'search?where[cityNormalized][startsWith]=' +
              formattedValue +
              '&distinct=zip&[take]=100',
            {
              headers: _this.headers
            }
          )
          .then((response) => {
            self.citySelection = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (self.loadingCities = false));
      }
    },
    getUnallocatedDistricts(district) {
      if (!district.includes('(')) {
        this.loadingDistricts = true;
        axios
          .get(this.pastURL + 'districts?district=' + district, {
            headers: this.headers
          })
          .then((response) => {
            if (response.data && response.data.length) {
              this.districtItems = response.data;
              this.loadingDistricts = false;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.loadingDistricts = false));
      }
    },
    getDistricts(city, zip) {
      this.loadingDistricts = true;
      axios
        .get(this.pastURL + 'districts?city=' + city + '&zip=' + zip, {
          headers: this.headers
        })
        .then((response) => {
          if (response.data && response.data.length) {
            this.districtItems = response.data;
          } else {
            this.getDistrictsFallback(city);
          }
        })
        .catch((error) => {
          console.log(error);
          this.getDistrictsFallback(city);
        })
        .finally(() => (this.loadingDistricts = false));
    },
    getDistrictsFallback(city) {
      this.loadingDistricts = true;
      axios
        .get(this.pastURL + 'search?where[city][equals]=' + city, {
          headers: this.headers
        })
        .then((response) => (this.districtItems = response.data))
        .catch((error) => console.log(error))
        .finally(() => (this.loadingDistricts = false));
    },
    toggleActive(item) {
      if (this.activeItem[item.id]) {
        this.removeActiveItem(item);

        return;
      }

      this.addActiveItem(item);
    },
    addActiveItem(item) {
      this.activeItem = Object.assign({}, { [item.id]: item });
    },
    removeActiveItem(item) {
      delete this.activeItem[item.id];
      this.activeItem = Object.assign({}, this.activeItem);
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllDistricts) {
          this.district = [];
        } else {
          this.district = this.districts.slice();
        }
      });
    },
    showDistrict(district) {
      if (district) {
        this.district = district;
        this.getConstructionQuota();
      }
    },
    getItemText(item) {
      let cityLabel;
      if (this.citySelection && this.citySelection.length >= 1) {
        cityLabel = `${item.city} (${item.zip})`;
      } else {
        // cityLabel = '';
      }
      return cityLabel;
    },
    getDistrictItemText(item) {
      let districtLabel;
      if (this.districtItems && this.districtItems.length >= 1) {
        districtLabel = `${item.district} (${item.zip}, ${item.city})`;
      } else {
        // cityLabel = '';
      }
      return districtLabel;
    }
  }
};
</script>
<style lang="scss" scoped>
.input-row-adress-data {
  display: grid;
  grid-template-columns: auto max-content max-content;
  grid-gap: var(--goe-spacing-1);
}
</style>
